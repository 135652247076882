import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewForm extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Form/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetASingleForm extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Form/GetBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class GetAllForms extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Form/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class DeleteASingleForm extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Form/DeleteBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateASingleForm extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Form/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class CompletelyCreateForm extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Form/CompleteCreate");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  CreateNewForm,
  GetASingleForm,
  GetAllForms,
  DeleteASingleForm,
  UpdateASingleForm,
  CompletelyCreateForm,
};
