<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
        <h1>صندوق ورودی فرم های تماس</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="formsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <template #cell(createDate)="data">
              {{ new Date(data.item.createDate).toLocaleDateString("fa-IR") }}
            </template>
            <template #cell(description)="data">
              <p class="short-desc">
                {{ data.item.description }}
              </p>
            </template>
            <template #cell(actions)="data">
              <b-link
                :to="{
                  name: 'pages-forms-single',
                  params: { formId: data.item.formId },
                }"
              >
                <feather-icon icon="EyeIcon" size="20" class="text-primary" />
              </b-link>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "صندوق ورودی",
  data() {
    return {
      formsList: null,
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      searchCommand: null,
      myTableColumns: [
        {
          key: "formId",
          label: "شناسه فرم",
        },
        {
          key: "name",
          label: "عنوان فرم",
        },
        {
          key: "description",
          label: "توضیحات فرم",
        },
        {
          key: "dataCount",
          label: "تعداد پاسخ ثبت شده",
        },
        {
          key: "createDate",
          label: "تاریخ ایجاد",
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
    };
  },
  mounted() {
    this.getAllForms();
  },
  methods: {
    async getAllForms() {
      try {
        this.isLoading = true;
        let qParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        if (this.searchCommand) {
          qParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllForms = new GetAllForms(_this);
        getAllForms.setRequestParam(qParams);
        await getAllForms.fetch((response) => {
          if (response.isSuccess) {
            _this.formsList = response.data.forms;
            _this.totalCount = response.data.formsCount;
            console.log(_this.formsList);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BCard,
    BPagination,
    BContainer,
    BFormRow,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetAllForms } from "@/libs/Api/Form";
</script>
<style>
.short-desc {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
